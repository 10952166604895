import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage, getImage } from "gatsby-plugin-image";

import LayoutWidget from "../../../components/widgets/layout";
import Seo from "../../../components/seo";
import RentwareArticlesGrid from "../../../components/rentwareArticlesGrid";
import useTranslation from "../../../hooks/useTranslation";

const DemoSingleDayWidget = () => {
	const { language } = useTranslation();
	const data = useStaticQuery(graphql`
		{
			allFile(
				filter: { base: { regex: "/tesla-dark.jpg|porsche-taycan.png/" } }
			) {
				nodes {
					base
					childImageSharp {
						gatsbyImageData(width: 1440)
					}
				}
			}
		}
	`);
	const getTeslaImage = data.allFile.nodes.find((element) => {
		if (element.base === `tesla-dark.jpg`) {
			return true;
		}

		return false;
	});
	const getPorscheImage = data.allFile.nodes.find((element) => {
		if (element.base === `porsche-taycan.png`) {
			return true;
		}

		return false;
	});
	const teslaImg = getImage(getTeslaImage);
	const porscheImg = getImage(getPorscheImage);

	const articles = [
		{
			h1: "Tesla Model S",
			h2:
				language === "de"
					? "402mi Reichweite (EPA geschätzt)"
					: "402mi Range (EPA est.)",
			text:
				language === "de"
					? "Das Tesla Model S war 2013 'World Green Car of the Year', 2013 'Motor Trend Car of the Year', 'Automobile Magazine's 2013 Car of the Year', 'Time Magazine's Best 25 Inventions of the Year 2012' und 'Consumer Reports' top scoring car in road testing'."
					: "The Tesla Model S was the 2013 World Green Car of the Year, 2013 Motor Trend Car of the Year, Automobile magazine's 2013 Car of the Year, Time Magazine's Best 25 Inventions of the Year 2012, and Consumer Reports' top-scoring car in road testing.",
			href: "/widgets/demo/tesla",
			image: teslaImg,
			articleId: "GXHBU7",
			view: "slots",
		},
		{
			h1: "Porsche Taycan",
			h2: "28,7 – 28,0 kWh/100 km",
			text:
				language === "de"
					? "Puristisch, expressiv, zeitlos: Ausdrucksstärken, die den Taycan förmlich durchdringen – zusätzlich betont von der dynamischen, flachen Flyline. Auch beim Thema Freiraum hält er sich nicht zurück, sondern bietet neben Heck- und Frontkofferräumen exklusiven Komfort für 4 Personen."
					: "Purist, expressive, timeless: expressive strengths that literally permeate the Taycan - further emphasised by the dynamic, flat flyline. It does not hold back when it comes to space either, but offers exclusive comfort for 4 people in addition to rear and front luggage compartments.",
			href: "/widgets/demo/porsche",
			image: porscheImg,
			articleId: "8B83RM",
			view: "slots",
		},
	];

	return (
		<LayoutWidget>
			<Seo
				seo={{
					metaTitle: "Demo",
					metaDescription: "It looks like you got to the demo page",
				}}
			/>

			<div className="mb-16 hidden text-center lg:block">
				<StaticImage
					src="../../../images/tesla.jpg"
					alt="Tesla red"
					layout="constrained"
					width={1440}
					className=""
					quality="80"
				/>
			</div>

			<RentwareArticlesGrid articles={articles} />
		</LayoutWidget>
	);
};

export default DemoSingleDayWidget;
